import Card from "react-bootstrap/Card";

function Cards() {
  return (
    <>
      <Card className="project-card">
        <Card.Body>
          <Card.Text>
            <h3>Bakery Shop</h3>
          </Card.Text>
        </Card.Body>
        <a href="https://github.com/brokans/bakery-shop" target="_blank" rel="noopener noreferrer">
          <Card.Img variant="bottom" src="./Bakery-Shop-products.png" />
        </a>        
      </Card>
      <br />
      <Card className="project-card">
        {/* <Card.Body>
          <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text>
        </Card.Body> */}
        <a href="https://github.com/brokans/bakery-shop" target="_blank" rel="noopener noreferrer">
          <Card.Img variant="bottom" src="./Bakery-Shop-Employees.png" />
        </a>        
      </Card>
      <br />
      <hr />
      <br />
      <Card className="project-card">
        <Card.Body>
          <Card.Text>
            <h3>FE</h3>
            {/* Some quick example text to build on the card title and make up the
            bulk of the card's content. */}
          </Card.Text>
        </Card.Body>
        <a href="https://github.com/brokans/Company-Tests/tree/main/fe" target="_blank" rel="noopener noreferrer">
          <Card.Img variant="bottom" src="./fe-list.png" />
        </a>        
      </Card>
      <br />
      <Card className="project-card">
        {/* <Card.Body>
          <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text>
        </Card.Body> */}
        <a href="https://github.com/brokans/Company-Tests/tree/main/fe" target="_blank" rel="noopener noreferrer">
          <Card.Img variant="bottom" src="./fe-edit.png" />
        </a>        
      </Card>
        <br />
        <hr />
      <br />
      <Card className="project-card">
        <Card.Body>
          <Card.Text>
            <h3>Tic-Tac-Toe</h3>
          </Card.Text>
        </Card.Body>
        <a href="https://github.com/brokans/Company-Tests/tree/main/tic-tac-toe" target="_blank" rel="noopener noreferrer">
          <Card.Img variant="bottom" src="./tic-tac-toe-start.png" />
        </a>        
      </Card>
      <br />
      <Card className="project-card">
        {/* <Card.Body>
          <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text>
        </Card.Body> */}
        <a href="https://github.com/brokans/Company-Tests/tree/main/tic-tac-toe" target="_blank" rel="noopener noreferrer">
          <Card.Img variant="bottom" src="./tic-tac-toe-game.png" />
        </a>        
      </Card>
      <br />
      <hr />
      <br />
      <Card className="project-card">
        <Card.Body>
          <Card.Text>
            <h3>Trinidad Wiseman</h3>
            {/* Some quick example text to build on the card title and make up the
            bulk of the card's content. */}
          </Card.Text>
        </Card.Body>
        <a href="https://github.com/brokans/Company-Tests/tree/main/wiseman" target="_blank" rel="noopener noreferrer">
          <Card.Img variant="bottom" src="./wiseman-article.png" />
        </a>        
      </Card>
      <br />
      <Card className="project-card">
        {/* <Card.Body>
          <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text>
        </Card.Body> */}
        <a href="https://github.com/brokans/Company-Tests/tree/main/wiseman" target="_blank" rel="noopener noreferrer">
          <Card.Img variant="bottom" src="./wiseman-table.png" />
        </a>        
      </Card>
      <br />
      <Card className="project-card">
        {/* <Card.Body>
          <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text>
        </Card.Body> */}
        <a href="https://github.com/brokans/Company-Tests/tree/main/wiseman" target="_blank" rel="noopener noreferrer">
          <Card.Img variant="bottom" src="./wiseman-game.png" />
        </a>        
      </Card>
    </>
  );
}

export default Cards;
