import Card from "react-bootstrap/Card";

function Cards() {
  return (
    <>
      <Card className="project-card">
        <a href="https://line-stuudio.web.app/" target="_blank" rel="noopener noreferrer" >
          <Card.Img variant="top" src="https://i.postimg.cc/xTZR9JBY/perspektiiv-web.jpg" />
        </a>

        <Card.Body>
          {/* <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text> */}
        </Card.Body>
      </Card>
      <br /> <br />
      <Card className="project-card">
        <a href="https://bros-webshop.web.app/" target="_blank" rel="noopener noreferrer">
          <Card.Img
            className="project-img"
            variant="top"
            src="https://i.postimg.cc/FKw4QxxD/WebShop.jpg"
          />
        </a>
        <Card.Body>
          {/* <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text> */}
        </Card.Body>
      </Card>
      <br />
    </>
  );
}

export default Cards;
