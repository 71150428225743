import React from "react";

import Image from "react-bootstrap/Image";
import Cards from "../components/home/ProjectCards";
import TestCards from "../components/home/TestCards";
import Footer from "../components/home/Footer";
import { Contact } from "../components/home/Contact";

function HomePage() {
  return (
    <div>
      <div className="profile-div">
        <div className="profile-img-container">
          <Image
            className="profile-image"
            src="./Profiilikas.jpeg"
            rounded
          />
        </div>
        <div className="profile-txt-container">
          <div className="profile-txt">
            
            <h1>Web Developer</h1>
            <h4 className="name">Mario Brokans</h4>
            <p>
              Programmer and 3D modeler. <br />
              I also work on mobile apps and games.
            </p>
          </div>
        </div>
      </div>
      <div className="koolitused-container">
        <div className="koolitused-txt-container">
          <div className="koolitused-txt">
            <h2>STUDIED</h2>
            <p>JavaScript & React: from zero to specialist course.</p>
            <p>Python Classes and Inheritance.</p>
            <p>Data Collection and Processing with Python.</p>
            <p>Python Functions and Dictionaries.</p>
            <p>Introduction to Back-End Development.</p>
            <p>Coursera Python Basics.</p>
            <p>Create and Design a Modern Interior in Blender 2.8.</p>
          </div>
        </div>
        <div className="koolitused-img">
          <Image
            className="koolitused-image"
            src="./computer-coding.png"
            rounded
          />
        </div>
      </div>
      <div className="projects">
        <h2 className="project-header" id="projects">
          Projects
        </h2>
        <br />
        <p>Click on the images to open the website.</p>
        <Cards />
      </div>

      <br />
      <h2 id="tests">Corporate Tests</h2>
      <p>Click on the images to open the source code.</p>
      <br />
      <TestCards />
      <br /> <br />
      <h2>3D Modeling with Blender</h2>
      <br />
        <img className="model" src="/Tuba_RenderFinal.png" alt="" />
      <div className="contact-container">
        <div className="contact-me">
          <h2 id="contacts">Let's chat!</h2> <br />
          <Contact />
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
}

export default HomePage;
